



















import Vue from 'vue';
import CreateDynamicFormRequestViewModel from '@/src/services/viewModel/resource/CreateDynamicFormRequestViewModel';
import DynamicFormResourceViewModel from '@/src/services/viewModel/resource/DynamicFormResourceViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    isLoading: false,
    errorResponse: undefined as any,
    output: new CreateDynamicFormRequestViewModel(),
    formDefinition: new DynamicFormResourceViewModel(),
  }),
  async mounted() {
    this.isLoading = true;
    try {
      this.formDefinition = await this.$service.api.dynamicForms.getKitchenSink();
    } catch (error) {
      this.errorResponse = error;
    }
    this.isLoading = false;
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      try {
        this.$service.api.dynamicForms.createDynamicForm(this.output);
      } catch (error) {
        this.errorResponse = error;
      }
      this.isLoading = false;
    },
  },
});
